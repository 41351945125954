<template>
  <div>
    <qrcode-vue ref="qr" :value="text" :size="size" :level="level"></qrcode-vue>
    <br>
    <input v-model="text" placeholder="Enter text for QR code">
    <input v-model.number="size" placeholder="Enter size">
    <select v-model="level">
      <option value="L">L</option>
      <option value="M">M</option>
      <option value="Q">Q</option>
      <option value="H">H</option>
    </select>
    <button @click="downloadQR">Download QR code</button>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue
  },
  data () {
    return {
      text: 'https://www.example.com',
      size: 100,
      level: 'M'
    }
  },
  methods: {
    downloadQR() {
        this.$nextTick(()=>{
            const canvas = this.$refs.qr.$el
            if(canvas){
                const imgData = canvas.toDataURL('image/png')
                const link = document.createElement('a')
                link.download = 'QRcode.png'
                link.href = imgData
                link.click()
            }
            else{
              console.log('fdsfsdfdsgf')
            }
        })
    }
  }
}
</script>
